/**
 * @file
 *
 * Common code shared between client and the server.
 */

const moment = require('moment')
const iban = require('iban')
const map = require('lodash/map')
const mapValues = require('lodash/mapValues')
const isArray = require('lodash/isArray')
const isString = require('lodash/isString')
const isObject = require('lodash/isObject')
const some = require('lodash/some')

/**
 * Common DateFormats for the application.
 *
 * Note that we tend to use the first format as a display format, whereas the other formats are for
 * parsing dates. However due to the nature of the application and dynamic form fields, we don't
 * really enforce a format for the dates being submitted. This means that the receiving end might
 * end up with dates in varying formats.
 *
 * If this becomes a problem in the future, we should implement a simple map for the form values on
 * the server, where each valid date parsed against the formats below, will be formatted to one
 * agreed upon format.
 */
module.exports.DATE_FORMAT = [
  'DD.MM.YYYY',
  'MM/DD/YYYY',
  'YYYY-MM-DD'
]

/**
 * In a sense these formats are also tied to locales.
 */
module.exports.DATE_FORMAT_BY_LOCALE = {
  fi: 'DD.MM.YYYY',
  en: 'MM/DD/YYYY',
  sv: 'YYYY-MM-DD'
}

/**
 * Default locale for the application.
 */
module.exports.DEFAULT_LOCALE = 'fi'

/**
 * Extension for DateTime parsing.
 */
module.exports.datetime = {
  parse (value) {
    return +moment.utc(value, module.exports.DATE_FORMAT, true)
  },
  format (value) {
    return moment(value).format(module.exports.DATE_FORMAT[0])
  }
}

module.exports.ssid = (value, options, key) => {
  // first validate the format of hetu nnnnnn[-ABCDEFUVWXY]nnnc
  const re = new RegExp('^[0-9]{2}\\.?[0,1][0-9]\\.?[0-9]{2}[-ABCDEFUVWXY][0-9]{3}[0-9A-Z]')

  if (!re.test(value)) {
    return options.message
  }

  // then take the 9 numbers of hetu
  const datestr = value.substring(0, 6)
  const numstr = value.substring(7, 10)

  const numbers = datestr + numstr
  const m = numbers % 31

  // the 31 check characters
  const arr = '0123456789ABCDEFHJKLMNPRSTUVWXY'
  // the last character of hetu
  const c = value.substring(value.length - 1)

  if (arr.charAt(m) !== c) {
    return options.message
  }
}

module.exports.ibanCheck = (value, options, key) => {
  if (!iban.isValid(value)) {
    return options.message
  }
}

module.exports.later = (value, options, key, attributes) => {
  const v = moment.utc(value, module.exports.DATE_FORMAT)
  const c = moment.utc(attributes[options.comparison], module.exports.DATE_FORMAT)

  if (options.equal) {
    if (v.isBefore(c)) {
      return options.message
    }
  } else {
    if (v.isSameOrBefore(c)) {
      return options.message
    }
  }
}

module.exports.earlier = (value, options, key, attributes) => {
  const c = moment.utc(value, module.exports.DATE_FORMAT)
  const v = moment.utc(attributes[options.comparison], module.exports.DATE_FORMAT)

  if (options.equal) {
    if (v.isBefore(c)) {
      return options.message
    }
  } else {
    if (v.isSameOrBefore(c)) {
      return options.message
    }
  }
}

module.exports.dependant = (value, options, key, attributes) => {
  if (attributes[options.field] === options.value) {
    if (!(value && value.length > 0)) {
      return options.message
    }
  }
}

module.exports.dependsOnMany = (value, options, key, attributes) => {
  const hideMessage = some(options.fields, (option) => {
    return attributes[option.field] !== option.value
  })
  if (!hideMessage) {
    if (!(value && value.length > 0)) {
      return options.message
    }
  }
}

module.exports.filterDependantsFromConstraints = (object, constraints) => {
  let filteredConstraints = {}
  for (var key in constraints) {
    // Check dependency - if not true skip
    if (constraints.hasOwnProperty(key) && constraints[key].hasOwnProperty('dependant') && (object[constraints[key].dependant.field] !== constraints[key].dependant.value)) {
      continue
    } else if (constraints.hasOwnProperty(key) && constraints[key].hasOwnProperty('dependsOnMany')) {
      const canContinue = some(constraints[key].dependsOnMany.fields, (constraint) => {
        return object[constraint.field] !== constraint.value
      })
      if (canContinue) {
        continue
      }
    }
    filteredConstraints[key] = constraints[key]
  }
  return filteredConstraints
}

module.exports.clean = function clean (value) {
  if (isArray(value)) return map(value, clean)
  if (isObject(value)) return mapValues(value, clean)
  if (isString(value) && value.trim() === '') return null
  return value
}

/**
 * Check that the given date is after the earliest repeating date in the future or that at the
 * earliest it's the current date.
 */
module.exports.repeatingDate = function repeatingDate (value, options, key, attributes) {
  const c = moment.utc().startOf('day')
  const v = moment.utc(value, module.exports.DATE_FORMAT)
  if (v.isBefore(c)) {
    return options.message
  }
  const [attr, property] = options.target.split('.')
  if (isArray(attributes[attr])) {
    // Collect the repeating date values, ignore any non-valid dates.
    const repeatingDates = attributes[attr]
      .map(attr => moment(attr[property], module.exports.DATE_FORMAT))
      .filter(date => date.isValid())
    if (repeatingDates.length > 0) {
      const earliestRepeatingDate = moment.min(repeatingDates)
      // If the earliest repeating date is in the future, the given date must not be any earlier
      // than that. However the given date must always be at least today.
      if (c.diff(earliestRepeatingDate) < 0 && v.isBefore(earliestRepeatingDate)) {
        return options.message
      }
    }
  }
}
