/**
 * @file
 *
 * The client-side scripts.
 */

import 'browsernizr/test/inputtypes'
import 'browsernizr/test/touchevents'

import moment from 'moment'
import modernizr from 'browsernizr'

import get from 'lodash/get'
import set from 'lodash/set'
import keys from 'lodash/keys'
import isObject from 'lodash/isObject'
import cloneDeepWith from 'lodash/cloneDeepWith'
import isUndefined from 'lodash/isUndefined'
import pick from 'lodash/pick'

import validator from '../../common/validate'
import { filterDependantsFromConstraints, clean, DATE_FORMAT, DATE_FORMAT_BY_LOCALE } from '../../common'

// Note that the following is a bit of a hack around the lack of CommonJS support in the DatePicker
// module chosen. Normally you can just import 'jquery' the regular way. Unfortunately the normal
// way of providing globals with 'browserify-shim' doesn't really seem to work with multi-bundles.
const $ = window.$ = window.jQuery = require('jquery')

require('air-datepicker')
require('./localization/**/*.js', { mode: 'expand' })

/**
 * Extend the DateTime validator.
 */
validator.extend(validator.validators.datetime, require('../../common').datetime)
validator.validators.later = require('../../common').later
validator.validators.earlier = require('../../common').earlier
validator.validators.ssid = require('../../common').ssid
validator.validators.iban = require('../../common').ibanCheck
validator.validators.dependant = require('../../common').dependant
validator.validators.dependsOnMany = require('../../common').dependsOnMany
validator.validators.repeatingDate = require('../../common').repeatingDate

/**
 * Keep track of datepickers.
 *
 * @type {Object[]}
 */
let pickers = []

/**
 * We don't want to validate presence until the form is sent.
 *
 * @type {Object}
 */
const PRESENCE_FREE_CONSTRAINTS = getPresenceFreeConstraints()

/**
 * For non-final validation we don't check for presence since it can produce a large number of
 * errors for non-touched fields.
 *
 * The usage of 'cloneDeepWith' here is a bit questionable. Replace with something more elegant.
 * However make sure the 'window.constraints' is not being modified (JSON.parse/stringify).
 *
 * @return {Object}
 */
function getPresenceFreeConstraints () {
  const omit = (value) => {
    if (isObject(value)) {
      delete value.presence
    }
  }
  return cloneDeepWith(JSON.parse(JSON.stringify(window.constraints || {})), omit)
}

/**
 * Change the locale used in the application.
 *
 * @param {String} locale Locale or language to use. E.g. "fi", "sv" or "en".
 */
function setLocale (locale) {
  window.locale = locale
  if (window.sessionStorage) {
    window.sessionStorage.setItem('locale', locale)
  }
  $('[data-translate]').each((i, el) => {
    const key = $(el).attr('data-translate')
    translate(el, key)
  })
  $('[data-language]').each((i, el) => $(el).attr('data-language', locale))
}

function translate (el, key) {
  const translation = get(window.translations, [key, window.locale]) || key
  if ($(el).is('[data-noescape]')) {
    return $(el).html('').append(translation)
  }
  $(el).text('').text(translation)
}

/**
 * Validate given object.
 *
 * @param   {Object} object      Object to validate.
 * @param   {Object} constraints Constraints to validate the given object against.
 * @returns {Boolean}
 */
function validate (object, constraints) {
  // skip disabled fields when validating prefilled form
  if (window.prefill) {
    constraints = pick(PRESENCE_FREE_CONSTRAINTS, window.prefillable)
  }
  // The 'raw' model is has fields like 'foo[0][bar]' matching the field names exactly. On server
  // these paths are actually exploded into actual objects. See below how we mimic this on client.
  keys(object).forEach((field) => {
    $(`[name="${field}"]`).removeClass('is-danger').removeClass('error')
    $(`[data-validation="${field}"]`).removeAttr('data-translate').text('')
  })
  // Explode the paths such as 'foo[0][bar]' into actual object structure so we can validate the
  // model properly.
  const model = keys(object).reduce((model, key) => {
    const v = object[key]
    const k = key.replace(/\[/g, '.').replace(/\]/g, '')
    return set(model, k, v)
  }, {})
  const errors = validator.validate(model, filterDependantsFromConstraints(object, constraints))
  if (errors && keys(errors).length > 0) {
    keys(errors).forEach((name) => {
      // However errors are then printed in a 'foo[0].bar' format, so we need to translate these
      // back into the 'foo[0][bar]' format.
      const field = [
        name.split('.').shift(),
        name.split('.').slice(1).map(p => `[${p}]`).join('')
      ].join('')
      // Also we'll need to make sure to clean up the error so we'll end up with 'ERR_SOMETHING' and
      // not something like 'Foo Bar ERR_SOMETHING'. Because of reasons.
      let error = errors[name][0]
      error = error.substring(error.indexOf('ERR_'))

      $(`[name="${field}"]`).addClass('is-danger').addClass('error')
      $(`[data-validation="${field}"]`).each(function (i, el) {
        translate(el, error)
      })
    })
  }
  return !(errors && keys(errors).length > 0)
}

/**
 * Toggle element visibility given a target and an expression.
 *
 * @param {Element} el         Element to toggle.
 * @param {Element} target     Target node to check expression against.
 * @param {String}  expression Expression like "name=value" or just "name".
 */
function toggle (el, target, expression) {
  let condition
  // Some elements are meant to remain open regardless.
  if (!isUndefined($(el).attr('data-keep-open')) && $(el).is(':visible')) {
    return
  }
  if ($(target).attr('type') === 'checkbox') {
    // If the target is a checkbox, we must operate on the "checked" property.
    condition = $(target).get(0).checked
    if (expression.length > 1) {
      // If the expression contains more than just a name, we must handle some form of an equality
      // check. In the case of checkbox we must check whether a "true" or "false" is given for the
      // checked property.
      if (['true', 'false'].indexOf(expression[1]) >= 0) {
        condition = $(target).get(0).checked === (expression[1] === 'true')
      } else {
        console.warn(`[toggle] ignoring expression "${expression.join('=')}" for checkbox.`)
        return
      }
    }
  } else if ($(target).attr('type') === 'radio') {
    // For radio groups, the inputs share the same name, so we must check whether or not the input with the name and
    // the given value in the expression is checked.
    if (expression.length !== 2) {
      console.warn(`[toggle] invalid expression "${expression.join('=')}" for radio-group input.`)
      return
    }
    condition = $(`input[name=${expression[0]}][value=${expression[1]}]`).get(0).checked
  } else {
    // For a non-checkbox, we must also take into account the fact that we may simply check for the
    // existence of a value.
    condition = $(target).get(0).value && $(target).get(0).value.length > 0
    if (expression.length > 1) {
      condition = $(target).get(0).value === expression[1]
    }
  }
  console.info(`[toggle] toggling element [${condition}]`, el, target, expression)
  return $(el).toggle(condition)
}

/**
 * Each select with a 'filter' attribute can filter the 'options' of another select. Essentially
 * this just does some hackish thing to hide and show values.
 *
 * @param {Element} select Select element used as the dictating party.
 */
function filterSelect (select) {
  const $target = $(`select[name=${$(select).attr('data-filter')}]`)
  const prvalue = $target.val()
  $target.find('option').each((i, option) => {
    // First we unwrap any options from inside the span that hides them.
    if ($(option).parent().is('span')) {
      $(option).unwrap()
    }
    // Then we see if the option should've been wrapped after all...
    if ($(option).attr('data-filter') && $(option).attr('data-filter') !== $(select).val()) {
      $(option).wrap('<span />')
    }
  })
  // In order to prevent the case where running the filter initially on the target will lose the
  // value set for it, we will perform some trickery.
  const values = $target.find('option')
    .filter((i, option) => !$(option).parent().is('span'))
    .map((i, option) => $(option).val())
    .toArray()
  if (values.indexOf(prvalue) >= 0) {
    $target.val(prvalue)
  }
  $target.trigger('change')
}

/**
 * Change the header image to match the locale.
 *
 * Note that we perform a simple regex so we shouldn't mess up custom images as long as they don't
 * have the locale in the URL. E.g. 'custom-image.en.png' would be bad if there's no 'sv' and 'fi'
 * versions of that image..
 *
 * @param {String} locale Current locale.
 */
function setLocalizedHeaderIMG (locale) {
  const $header = $('.page-header img')
  $header.attr('src', $header.attr('src').replace(/\.(fi|en|sv|ee)\.png/, `.${locale}.png`))
}

/**
 * Setup complex elements such as datepicker-elements here.
 */
function setupComplexElements () {
  // Setup help-buttons to be hidden by default.
  $('[data-is-help]').each((i, el) => $(el).hide())
  // Setup datepicker-elements.
  pickers = pickers.filter(picker => $.contains(window.document.body, picker.$el.get(0)))
  if (!(modernizr.inputtypes.date && modernizr.touchevents)) {
    $('input[type=date]').each((i, el) => {
      // Skip template date fields
      if ($(el).parents('template').length > 0) return
      let startDate
      $(el).attr('type', 'text')
      if (moment($(el).val(), DATE_FORMAT).isValid()) {
        $(el).val(moment($(el).val(), DATE_FORMAT).format(DATE_FORMAT_BY_LOCALE[window.locale]))
        startDate = moment($(el).val(), DATE_FORMAT).toDate()
      }
      const picker = $(el).datepicker({
        startDate,
        language: window.locale,
        position: 'top left',
        autoClose: true
      })
      var pickerInstance = picker.data('datepicker')
      pickers.push(pickerInstance)
      if (startDate) pickerInstance.selectDate(startDate)
    })
  }
}

/**
 * Setup any necessary triggers.
 */
$(document).ready(() => {
  /**
   * Reference to the form.
   *
   * @type {Element}
   */
  const $form = $('form:not([novalidate])')

  // When the form is submitted, we must run the validators against the model and if invalid, we
  // scroll back to the first error on the form (and a bit above).
  $form.submit((event) => {
    event.preventDefault()
    if (!validate(clean(validator.collectFormValues(event.currentTarget)), window.constraints)) {
      var $errorElements = $('.error').filter(function (i, el) {
        return (el.offsetParent !== null) // filter away hidden elements
      })
      if ($errorElements && $errorElements.length > 0) {
        const $focusElems = $($errorElements[0].closest('.field')).siblings()
        if ($focusElems && $focusElems.length > 0) {
          $focusElems[0].scrollIntoView()
        } else {
          $errorElements[0].closest('.field').scrollIntoView()
        }
      }
      return
    }
    event.currentTarget.submit()
  })

  /**
   * Perform validation without required checks.
   */
  const presenceFreeValidation = () => {
    validate(clean(validator.collectFormValues($form)), PRESENCE_FREE_CONSTRAINTS)
  }

  // Setup clicking on those help-buttons.
  $(document).on('click', '[data-help]', (event) => {
    $(`[data-is-help="${$(event.currentTarget).attr('data-help')}"]`).toggle()
  })

  // Setup the addition of elements.
  $(document).on('click', '[data-button-increment]', (event) => {
    event.preventDefault()
    // Name of the field we're adding multiples of.
    const name = $(event.currentTarget).attr('data-button-increment')
    // Respect limits set in the configuration so we don't add too many fields.
    const count = parseInt($(`input[type=hidden][name=count_${name}]`).val())
    const maximum = parseInt($(`input[type=hidden][name=count_${name}]`).attr('data-max'))
    if (count < maximum) {
      // Append the new element from the respecting template and increment the count. Perform any
      // initializations required for dynamic fields.
      $(`[data-template-container-id=${name}]`)
        .append($(`template[id=${name}]`).html().replace(/\[\]/g, `[${count}]`))
      $(`input[type=hidden][name=count_${name}]`).val(count + 1)
      // Setup any possible complex-elements that were added here.
      setupComplexElements()
      setLocale(window.locale)
    }
    if (count === (maximum - 1)) {
      // Disable add button if amount of items equals maximum.
      $(`button[class=button][name=count_${name}]`).attr('disabled', true)
      $('.tooltip__text').removeClass('tooltip__text--hide')
    }
    // Enable removing of all templates in case there is more then one of them
    $('button[data-button-decrement=' + name + ']').first().removeClass('hidden')
  })

  // Setup the removal of elements.
  $(document).on('click', '[data-button-decrement]', (event) => {
    event.preventDefault()
    // Name of the field we're removing.
    const name = $(event.currentTarget).attr('data-button-decrement')
    const $elementToRemove = $(event.currentTarget).closest('[data-template-id=' + name + ']')
    // Respect limits set in the configuration so we don't remove too many fields.
    const count = parseInt($(`input[type=hidden][name=count_${name}]`).val())
    const minimum = parseInt($(`input[type=hidden][name=count_${name}]`).attr('data-min'))
    if (count > minimum) {
      $elementToRemove.remove()
      $(`input[type=hidden][name=count_${name}]`).val(count - 1)
      // Enable add button.
      $(`button[class=button][name=count_${name}]`).attr('disabled', false)
      // Remove tooltip
      $('.tooltip__text').addClass('tooltip__text--hide')
    }
    // Afer removal when only one template left => hide delete button
    if (count === 2) {
      $('button[data-button-decrement=' + name + ']').first().addClass('hidden')
    }
    // Reindex dynamic elements
    $('[data-template-id=' + name + ']').each((templateI, elem) => {
      const $elem = $(elem)

      $elem.find('input[name*=\\[]').each((i, el) => {
        const elName = el.getAttribute('name').replace(/\[\d\]/, '[' + templateI + ']')
        el.setAttribute('name', elName)
      })

      $elem.find('select[name*=\\[]').each((i, el) => {
        const elName = el.getAttribute('name').replace(/\[\d\]/, '[' + templateI + ']')
        el.setAttribute('name', elName)
      })

      $elem.find('span[data-validation*=\\[]').each((i, el) => {
        el.dataset.validation = el.dataset.validation.replace(/\[\d\]/, '[' + templateI + ']')
      })
    })
    // Let's validate if dynamic element is removed to clear unnecessary errors
    presenceFreeValidation()
  })

  // Setup a listener for changing the language of the form. Note how we keep track of the picker-
  // elements and update their language as well. Note that when we update the picker it tends to
  // clear the input value, so we do reset it.
  $('[name="locale"]').change((event) => {
    const locale = event.currentTarget.value
    setLocale(locale)
    setLocalizedHeaderIMG(locale)
    pickers.forEach(picker => picker.update({ language: locale }))
    // Scroll to the beginning of the page
    document.body.scrollTop = document.documentElement.scrollTop = 0
  })
  $('[name="locale"]').val(window.locale).trigger('change')

  $('[data-capitalize=on]').change((event) => {
    const feed = event.currentTarget.value
    const $inputField = $('[name="' + event.currentTarget.name + '"]')

    if (feed) {
      $inputField.val(feed.toUpperCase())
    }
  })

  // Setup elements that are shown and hidden on certain conditions.
  $('[data-show-if]').each((i, el) => {
    const expression = $(el).data('show-if').split('=')
    const $dependency = $('[name="' + expression[0] + '"]')
    // Initial toggle for elements based on the initial values, this is needed because some pre-filled forms might not
    // hide every dependant element because of the given data.
    toggle(el, $dependency, expression)
    $dependency.change((event) => {
      const dataClear = !isUndefined($(el).attr('data-clear'))
      if (dataClear) {
        $(el).find('[type="checkbox"]').prop('checked', false)
        $(el).find('[type="text"]').val('')
        $(el).find('[type="number"]').val(null)
        $(el).find('select').val(null)
      }
      toggle(el, event.currentTarget, expression)
    })
    $(el).data('show-if-set', true)
  })
  // Make sure when select fields with 'filter' data-attribute change, that filters are updated.
  $('select[data-filter]').each((i, el) => {
    filterSelect(el)
    $(el).change(() => filterSelect(el))
  })

  // Any complex elements that require additional setup after adding and removing elements are
  // separated into their own functions.
  setupComplexElements()

  $(document).on('blur', 'input[name], select[name]', presenceFreeValidation)

  // There is a hidden field in form.handlebars - it sends out the date that the form was sent.
  // Works in all forms.
  const $submit = $('.submitbutton')
  const $send = $('.sendDate')
  $submit.on('click', () => {
    var currentDate = moment().format('DD.MM.YYYY')
    $send.val(currentDate)
  })
})
