/**
 * @file
 *
 * This file should load localization data.
 */

import $ from 'jquery'

$.fn.datepicker.language['ee'] = {
  days: ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
  daysShort: ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],
  daysMin: ['Sö', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö'],
  months: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
  monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
  today: 'I dag',
  clear: 'Nollställ',
  dateFormat: 'yyyy-mm-dd',
  timeFormat: 'hh:ii',
  firstDay: 1
}
